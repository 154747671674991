import React from 'react';

const ButtonCustom = (props) => {
  return (
      <button onClick={props.onClick}
         style={{width: props.width, 
         height: props.height, 
         fontSize:15, 
         borderRadius: 3, 
         outline:'none',
         backgroundColor: props.bgColor,
         color: "white",
         marginLeft:10,
         cursor: "pointer",
         opacity: props.opacity,
         border: "none",
         padding: "12px 20px",
         marginTop: props.marginTop
         }}>
        {props.text}
      </button>
  )
}

export default ButtonCustom;