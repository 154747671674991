import React, {Component} from 'react';
import {Link} from "react-router-dom";
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import axios from 'axios';
import Login from '../Login/Login';
import {API_URL} from '../../config/baseUrl';
import CustomSelect from '../CustomSelect/CustomSelect';
import ButtonCustom from '../ButtonCustom/ButtonCustom';
import moment from 'moment';

import './EmailData.css';
import { CSVLink } from "react-csv";

const headers = [
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Email", key: "email" }
  ];

const data = [
    { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
    { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
    { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
  ];

export default class EmailData extends Component {

state = {
    loading: true,
    unVerifiedEmailData: [],
    verifiedEmailData: [],
    allUsersEmailData: []
}

componentWillMount(){   
    this.getEmailDatas({verified: true}, 'verified');
    this.getEmailDatas({verified: false}, 'UNverified');
    this.getEmailDatas({}, 'allusers')
}

getEmailDatas = async (query, type) => {
    const USER_TOKEN = localStorage.getItem('token');
    const AuthStr = 'Bearer '.concat(USER_TOKEN); 
    try {
        this.setState({loading: true})
        const response = await axios.post(API_URL + '/admin/get-users-email-data', 
               query, {headers: { Authorization: AuthStr } });
              //console.log('email data', response.data)
         if(response.data.message == "success") {
             if(type == "verified") this.setState({verifiedEmailData: response.data.emailData});
             else if(type == "UNverified") this.setState({unVerifiedEmailData: response.data.emailData});
             else if(type == "allusers") this.setState({loading: false, allUsersEmailData: response.data.emailData});
         }else this.setState({loading: false})
    } catch (error) {
        //this.logOut()
        this.setState({loading: false})
        console.log('error ' + error);
    }
    
}




logOut = () => {
localStorage.clear();
    window.location.reload();
}

    

render() {
        const {loading, verifiedEmailData, unVerifiedEmailData, allUsersEmailData} = this.state;
        

        return (
            <div className="main-content-wrapper">
                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>Email Data</p>
                </div> 
                    
                <div className="row-title" >
                    
                </div>

                <div className="email-data-container" >
                { loading && <p class="animated-gradient-download"></p>}
                    <div className="email-data-rows" >
                        <h5>UNVERIFIED USERS EMAIL ({unVerifiedEmailData.length})</h5>
                        <div className="email-data-rows-btn">
                        { loading ? <p class="animated-gradient-download"></p> : 
                            <p>
                              <CSVLink 
                                data={unVerifiedEmailData} 
                                headers={headers}
                                filename={"UNverified-users.csv"}
                                >
                                 DOWNLOAD
                              </CSVLink>
                            </p>
                         }
 
                        </div>
                    </div>

                    <div className="email-data-rows" style={{background: '#B9D8FF'}} >
                        <h5>VERIFIED USERS EMAIL ({verifiedEmailData.length})</h5>
                        <div className="email-data-rows-btn">
                            { loading ? <p class="animated-gradient-download"></p> : 
                                <p>
                                    <CSVLink 
                                    data={verifiedEmailData} 
                                    headers={headers}
                                    filename={"verified-users.csv"}
                                    >
                                    DOWNLOAD
                                    </CSVLink>
                                </p>
                             }
                            
                        </div>
                    </div>

                    <div className="email-data-rows" style={{background: '#B9FFF0'}} >
                        <h5>ALL USERS EMAIL ({allUsersEmailData.length})</h5>
                        <div className="email-data-rows-btn">
                            { loading ? <p class="animated-gradient-download"></p> : 
                            <p>
                                <CSVLink 
                                    data={allUsersEmailData} 
                                    headers={headers}
                                    filename={"ALL-users.csv"}
                                    >
                                    DOWNLOAD
                                </CSVLink>
                            </p> 
                          }
                           
                        </div>
                    </div>
                   
                </div>

            </div>
               
        )
    }

}
