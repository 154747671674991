
export const countries = [
    {
      code: 'NG',
      telCode:  '234',
      name: "Nigeria",
      icon: './flags/NG.jpg',
    },
    {
        code: 'US',
        telCode:  '1',
        name: "United States",
        icon: './flags/US.jpg',
    },
    {
      code: 'GB',
      telCode:  '44',
      name: "United Kingdom",
      icon: './flags/GB.jpg'
    },

    {
      code: 'CA',
      telCode:  '1',
      name: "Canada",
      icon: './flags/CA.jpg'
    },

    {
      code: 'AU',
      telCode:  '61',
      name: "Australia",
      icon: '/flags/AU.jpg'
    },

    {
      code: 'GH',
      telCode:  '233',
      name: "Ghana",
     // icon: require('./Img/flags/GH.jpg')
    },

    {
      code: 'DE',
      telCode:  '49',
      name: "Germany",
     // icon: require('./Img/flags/DE.jpg')
    },

    {
      code: 'FR',
      telCode:  '33',
      name: "France",
     // icon: require('./Img/flags/FR.jpg')
    },

    {
      code: 'KE',
      telCode:  '254',
      name: "Kenya",
     // icon: require('./Img/flags/KE.jpg')
    },

    {
      code: 'NL',
      telCode:  '31',
      name: "Netherland",
     // icon: require('./Img/flags/NL.jpg')
    },

    {
      code: 'RU',
      telCode:  '7',
      name: "Russia",
     // icon: require('./Img/flags/RU.jpg')
    },

    {
      code: 'MY',
      telCode:  '60',
      name: "Malaysia",
     // icon: require('./Img/flags/MY.jpg')
    },

    {
      code: 'RW',
      telCode:  '250',
      name: "Rwanda",
     // icon: require('./Img/flags/RW.jpg')
    },

    {
      code: 'SA',
      telCode:  '27',
      name: "South Africa",
     // icon: require('./Img/flags/SA.jpg')
    },

    {
      code: 'IE',
      telCode:  '353',
      name: "Ireland",
     // icon: require('./Img/flags/IE.jpg')
    },

    {
      code: 'IT',
      telCode:  '39',
      name: "Italy",
     // icon: require('./Img/flags/IT.jpg')
    },

    {
      code: 'NA',
      telCode:  '261',
      name: "Namibia",
      //icon: require('./Img/flags/NA.jpg')
    },

    {
      code: 'QA',
      telCode:  '974',
      name: "Qatar",
      //icon: require('./Img/flags/QA.jpg')
    },

]
