import React from 'react';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import './CustomSelect.css';

const CustomSelect = (props) => {
  return (
      <div style={{position:'relative', marginRight:20}}>
         <div className="custom-select-container" 
            style={{}} onClick={props.onClick} >
            <div className="custom-select-wrapper">
              <h5>{props.selectedData.label}</h5>
              {props.isOpen ? <BsFillCaretUpFill/> : <BsFillCaretDownFill />}
            </div>
        </div>
        {props.isOpen && 
          <div className="custom-dropdown-wrapper">
             {props.data.map((item) => (
               <p key={item.value} onClick={() => props.onSelect(item)} >
                 {item.label}
              </p>
             ))}
          </div>
        }
      </div>
  )
}

export default CustomSelect;